<template>
  <div class="white-box">
    <div class="content">
      <div class="header" v-if="showTitle">
        <p class="main-title">Resumo</p>
      </div>
      <div class="patient-abstract">
        <b-row align-h="between">
          <b-col
            :cols="cols"
            class="mb-24"
            v-for="(subtitle, index) of subtitles"
            :key="index"
          >
            <p class="sub-title">{{ subtitle.name }}</p>
            <div class="tags">
              <p
                class="tag empty"
                v-if="!filterTypes(subtitle.type).length"
              >
                <InfoCircleFilled class="icon" /> sem dados
              </p>
              <SummaryItem
                v-for="(item, index) in filterTypes(subtitle.type)"
                :item="item"
                :key="index"
                @delete-summary-item="deleteSummaryItem"
              />
              <b-input
                v-if="!inactivePatient"
                class="tag-input"
                autocomplete="off"
                type="text"
                v-on:keyup.enter="addTag($event, subtitle.type)"
                placeholder="Adicionar item"
              />
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SummaryContainer',
  components: {
    SummaryItem: () => import('@/components/Patient/Dashboard/SummaryItem.vue'),
    InfoCircleFilled: () => import('@/assets/icons/info-circle-filled.svg')
  },
  props: {
    summaryData: {
      type: Array,
      required: true
    },
    patientId: {
      type: String,
      required: true
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    cols: {
      type: Number,
      default: 4
    },
    inactivePatient: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      subtitles: [
        {
          name: 'Doenças oftalmológicas',
          type: 'antecedentes-oftalmologicos'
        },
        {
          name: 'Cirurgias/procedimentos oftalmológicas',
          type: 'cirurgias-oftalmologicas-anteriores'
        },
        {
          name: 'Alergias',
          type: 'alergias'
        },
        {
          name: 'Doenças prévias',
          type: 'antecedentes-pessoais'
        },
        {
          name: 'Medicamentos em uso',
          type: 'outros-medicamentos-em-uso'
        },
        {
          name: 'Antecedentes familiares',
          type: 'antecedentes-familiares'
        }
      ]
    }
  },
  methods: {
    filterTypes(type) {
      return this.summaryData.filter(item => item.type === type)
    },
    async addTag(event, type) {
      const isLoading = this.$loading.show()
      try {
        const response = await this.api.storePatientSummary({
          person_id: this.patientId,
          name: event.target.value,
          type
        })
        this.$toast.success('Dado adicionado com sucesso!')
        event.target.value = ''
        this.$emit('refreshSummary', response.data)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    deleteSummaryItem(id) {
      this.$emit('refreshSummary', null, id)
    }
  }
}
</script>

<style lang="scss" scoped>
.tags {
  max-width: 90%;
  display: flex;
  flex-wrap: wrap;

  .tag {
    font-weight: 600;
    font-size: 12px;
    color: var(--type-active);

    &.empty {
      border-radius: 30px;
      background-color: var(--neutral-100);
      border: 1px dashed var(--neutral-200);
      color: var(--neutral-500);
      margin: 0 8px 8px 0;
      padding: 8px 8px;

      .icon {
        width: 16px;
        height: 16px;
        fill: var(--neutral-500);
        margin-right: 8px;
      }
    }
  }

  .tag-input {
    width: fit-content;
    height: fit-content;
    border: 1px solid var(--neutral-300);
    border-radius: 30px !important;
    padding: 8px 16px;
    font-size: 12px !important;
    &::-webkit-input-placeholder {
      font-size: 12px;
    }

    &:focus {
      outline: none;
    }
  }
}
</style>
